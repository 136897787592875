._json_string {
  color: #6a8759;
}
._json_number {
  color: #6897bb;
}
._json_boolean {
  color: #cc7832;
}
._json_null {
  color: #cc7832;
}
._json_key {
  color: #9876aa;
}
